@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://fonts.googleapis.com/css2?family=Raleway"); */

@font-face {
  font-family: "Raleway";
  src: url("./assets/font/Raleway-VariableFont_wght.ttf")
}


/* font-size: 36px;
color: #191919;
letter-spacing: 1.38px;
text-align: center;
line-height: 70px; */

/* html {
} */

:root {
  font-family: Raleway;

  /* font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif; */
  line-height: 1.5;
  font-weight: 400;
  color: #191919;
  background-color: #191919;


  /* max-width: 1280px; */
  /* padding: 2rem; */
  text-align: center;

  /* color-scheme: light dark; */
  /* color: rgba(255, 255, 255, 0.87); */
  /* background-color: #F6F5F4; */

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  font-size: 20px;
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  place-items: center;
  min-width: 320px;
  background-color: #F6F5F4;
}

h1 {
  font-size: 60px;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 36px;
  margin-bottom: 70px;
}

h4 {
  font-size: 24px;
  font-weight: 600;
}

p {
  font-size: 16px;
}

button {
  outline: none !important;
}

button:focus {
  outline: 0;
}

nav button {
  border-width: 0px;
}

.swatch-li {
  margin-bottom: 12px;
  /* margin-left: */
}

.swatch {
  margin-right: 14px;
}

.detail {
  font-size: 14px;
}

.App-header {
  /* background-color: #282c34; */
  /* min-height: 5vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-body {
  /* background-color: #282c34; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  /* background-color: #1a1a1a; */
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

.container {
  padding: 0 30px;
}

#section-product-pillars .icon-group {
  max-width: 85%;
}

#section-product-pillars .icon {
  max-width: 200px;
}

#section-contact-desktop .card-content {
  height: 388px;
}

#section-contact-desktop .card-content .card-left {
  /* width: 56%; */
}

#section-contact-desktop .card-content .card-right {
  /* width: 43%; */
}

#section-contact-desktop .card-content .profile-pic {
  border: 2px solid #FFF;
  box-shadow: 0 0 9px #888;
}

#service-highlights .panel {
  max-height: 388px;
  object-fit: contain;
  overflow: hidden;
}

#service-highlights .panel .text {
  margin: 20% auto;
}

#service-highlights img {
  display: flex;
  min-height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  /* overflow: hidden; */
  overflow-x: hidden;
}

.swiper-slide img {
  /* display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; */
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: contain;
}

/* body {
  background: #000;
  color: #000;
} */

.swiper {
  width: 100%;
  /* height: 509px; */
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
  /* margin-right: 5px !important; */
}

.mySwiper2 {
  /* height: 80%; */
  width: 100%;
  padding-bottom: 30px;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  /* height: unset; */
  /* height: 100%; */
  /* opacity: 0.4; */
}

.mySwiper .custom-active-thumb{
  opacity: 1;
}

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

#vignette-rooms .swiper {
  width: 100%;
  height: 100%;
}

#vignette-rooms .swiper-slide {
  /* text-align: center; */
  /* font-size: 18px; */
  /* background: #fff; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

#vignette-rooms .swiper-slide img {
  display: block;
  width: 200px;
  /* height: 100%; */
  object-fit: cover;
  min-height: 0;
  min-width: 0;
  /* max-width: 240px; */
}

#section-share {
  background: #FFFFFF;
}

#section-share button {
  border-left: 5px solid #FDB913;
  box-shadow: 0 11px 54px 0 rgba(0,0,0,0.04);
}

#section-saved-products-desktop .swiper-slide .panel img {
  min-width: unset;
  min-height: unset;
}

/* Surface Materials Panel */
.surface-materials-panel {
  position: absolute;
  /* bottom: 0; */
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 4px;
  padding: 6px 30px;
  color: white;
  display: flex;
  align-items: center;
  border: 2px solid rgba(255, 255, 255, 0.05);
  margin: 0 auto;
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.1);
}

.surface-materials-panel-label {
  font-family: 'Raleway';
  font-weight: 600;
  font-size: 13px;
  line-height: 1.2;
}

.surface-materials-panel-links {
  display: flex;
  align-items: center;
  margin-left: 32px;
  gap: 16px;
}

.surface-materials-panel-link {
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 13px;
  text-decoration: underline;
  color: white;
  transition: opacity 0.2s ease;
}

.surface-materials-panel-link:hover {
  opacity: 0.6;
  color: white;
}

/* Image Info Controls */
.image-info-controls {
  font-family: 'Raleway';
  font-size: 14px;
  color: white;
  text-shadow: 0 0 24px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  gap: 12px;
}

.image-info-controls span {
  opacity: 0.5;
}

.image-info-controls a {
  color: white;
  text-decoration: underline;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: opacity 0.2s ease;
}

.image-info-controls a:hover {
  opacity: 0.8;
  color: white;
}

/* Thumbnail Navigation */
.thumbnail-container {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(13px);
  box-shadow: 0 -4px 30px rgba(0, 0, 0, 0.15);
  padding: 20px 0;
}

.thumbnail-swiper {
  margin: 0 60px;
  padding: 0 40px;
}

.thumbnail-swiper .swiper-wrapper {
  justify-content: flex-start;
  margin: 0 auto;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.thumbnail-swiper .swiper-slide {
  aspect-ratio: 2/3;
  width: 200px !important;
  height: 100px !important;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.thumbnail-swiper .custom-active-thumb {
  /* transform: scale(1.05); */
  z-index: 1;
}

.thumbnail-swiper .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.thumbnail-swiper .custom-active-thumb img {
  border: 2px solid white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
}

.swiper-button-prev,
.swiper-button-next {
  width: 40px !important;
  height: 40px !important;
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 50%;
  z-index: 10;
  color: white !important;
}

.swiper-button-prev {
  left: -80px !important;
}

.swiper-button-next {
  right: -80px !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  display: none !important;
}

.swiper-button-prev svg,
.swiper-button-next svg {
  width: 21px;
  height: 33px;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 0.6;
}

.swiper-button-disabled {
  opacity: 0.35 !important;
  cursor: not-allowed;
}

.main-image-container {
  position: relative;
  height: 800px;
  overflow: hidden;
}

.pswp__img {
  object-fit: contain;
}

iframe {
  height: 100vh;
  width: 100vw;
}

.MuiTableCell-root {
  overflow: auto;
}

.MuiTableCell-paddingCheckbox {
  overflow: visible;
}

.highlight-header {
  font-size: 30px;
}

.highlight-subtext {
  font-size: 16px;
  font-weight: 500;

}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

/* Hide the last vignette divider */
.vignette-item-divider:last-child {
  display: none;
}

/* Add margin to last vignette group */
.section-vignettes .group:last-child {
  margin-bottom: 50px;
}

/* Vignette Summary Styles */
.thumbnail-swiper {
  padding: 8px 0;
}

.thumbnail-swiper .swiper-slide {
  transition: all 0.2s ease;
}

.thumbnail-swiper .swiper-button-next,
.thumbnail-swiper .swiper-button-prev {
  color: white;
  background: rgba(0, 0, 0, 0.5);
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.thumbnail-swiper .swiper-button-next:after,
.thumbnail-swiper .swiper-button-prev:after {
  font-size: 16px;
}

.thumbnail-swiper .swiper-button-next {
  right: 0;
}

.thumbnail-swiper .swiper-button-prev {
  left: 0;
}

@media (max-width: 1024px) {
  .thumbnail-swiper {
    margin: 0 40px;
  }
}

@media (max-width: 768px) {
  .thumbnail-swiper {
    margin: 0 20px;
    padding: 0;
  }
  .thumbnail-swiper .swiper-slide {
    width: 150px !important;
    height: 75px !important;
  }
  .thumbnail-swiper .swiper-slide img {
    /* height: 80%; */
    min-height: 130px !important;
    margin: auto 0;
  }

  .thumbnail-swiper {
    min-height: 150px !important;
  }
  
  .thumbnail-swiper .swiper-wrapper {
    height: 150px !important;
    /* max-height: 235px; */
  }
  
 .thumbnail-swiper .swiper-slide {
    height: 150px !important;
  }

  /* .swiper-thumbs .swiper-slide-thumb-active img {
    height: 100% !important;
  } */


  .thumbnail-swiper .swiper-button-prev {
    left: -10px;
  }

  .thumbnail-swiper .swiper-button-next {
    right: 10px;
  }

  #session-screenshots-swiper-images {
    max-height: 250px;
  }

  #section-product-pillars .icon-group {
    max-width: unset;
  }

  #section-share button {
    border-left: 3px solid #FDB913;
    box-shadow: 0 11px 54px 0 rgba(0,0,0,0.04);
  }
}

/* Center content in swiper slides */
/* .swiper-slide {
  justify-content: center;
} */

#vignette-rooms .swiper-wrapper {
  /* justify-content: center; */
  padding-bottom: 12px;
}

#vignette-rooms .swiper-wrapper:has(> .selected-product-slide) {
  display: flex;
  /* flex-basis: max-content; */
  /* align-items: center; */
}

#vignette-rooms .selected-product-slide .swiper-wrapper {
  display: flex;
  /* flex-basis: max-content; */
  /* align-items: center; */
}

#vignette-rooms .swiper-wrapper {
  /* justify-content: center; */
  padding-bottom: 12px;
}

#vignette-rooms .swiper-slide {
  /* justify-content: center; */
  margin-right: 25px !important;
}


