.share {
  padding-bottom: 3rem;
  background-color: #fff;
  border-bottom: 1px solid #E5E5E5;
}

.share__container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;
}

.share__title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 3rem;
  color: #333333;
  font-weight: 300;
}

.share__buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 35%;

  @media (min-width: 768px) {
    gap: 114px;
  }

}

.share__button {
  display: flex;
  align-items: center;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  height: 70px;
}

.share__button-icon {
  width: 32px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
}

.share__button-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.share__button-text {
  color: #333333;
  font-size: 0.875rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  padding: 6px 26px;
  border: 1px solid #333333;
  transition: all 0.3s ease;

  text-align: center;
  line-height: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

.share__button:hover .share__button-text {
  background-color: #333333;
  color: #ffffff;
}
